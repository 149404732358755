import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';

import React, {useEffect, useState, CSSProperties} from 'react';

import { IReportData } from '../shared/interfaces';
import { MatrixService } from "../services/matrixservice";
import { MatrixSettings } from "../models/matrixSettings";
import { SpinnerContainer } from "./shared/SpinnerContainer";
import axios from 'axios';

const matrixService = new MatrixService();
const matrixSettings = new MatrixSettings();

const apiURL = 	matrixSettings.awsUrl + "/get-dash-with-rls/"

const getEmbedUrl = async (embedId: string, topicembed:boolean = false) => {
  const _axiosHeaders = await matrixService.getHeaders();

  const command = apiURL;

  const postBody = topicembed ? { dashboardId: embedId, QTopic: topicembed } : { dashboardId: embedId }

  const response = await axios.post(
    command,
    postBody,
    {
      "headers": _axiosHeaders
    }
  ).catch((error) => {
    console.log("Error on embed URL retrieve: ", error);
    return {
      data: error.response.data,
      status: error.response.status
    }
  });

  return response;

}

const embedDashboard = async (dash:IReportData) => {

    let topicResponse = null;
    let dashResponse = null;

    if(dash.QTopicId && dash.QTopicId.length > 0)
    {
      let dashboardId = "";
      let topicId = "";

      if(dash.QTopicId === "0") {
        topicId = dash.DashboardId;

        topicResponse = await getEmbedUrl(topicId, true);

      }
      else {
        dashboardId = dash.DashboardId;
        topicId = dash.QTopicId;

        dashResponse = await getEmbedUrl(dashboardId)
        topicResponse = await getEmbedUrl(topicId, true);
      }
    } else {
      dashResponse = await getEmbedUrl(dash.DashboardId);
    }
    
    const dashDiv = document.getElementById('dashContainer') ?? document.createElement("div")
    const topicDiv = document.getElementById('topicContainer') ?? document.createElement("div");

    const dashEmbeddingContext = await QuickSightEmbedding.createEmbeddingContext({
      onChange: (changeEvent: any, metadata: any) => {
        console.log('Dashboard context received a change', changeEvent, metadata);
      }
    });;

    const topicEmbeddingContext = await QuickSightEmbedding.createEmbeddingContext({
      onChange: (changeEvent: any, metadata: any) => {
        console.log('Q Topic context received a change', changeEvent, metadata);
      }
    });;

    if(dashResponse) {
      if(dashResponse.status === 200) {

        let parameterOption:any = [];

        if(Object.hasOwn(dash, 'Parameters')) {
          const user:any = await matrixService.getCurrentUser();
          if(dash.Parameters && dash.Parameters.length > 0){
            dash.Parameters.forEach((param:any)=>{
              if (param.length > 0) {
                const _values = user[param];
                parameterOption.push({Name: param, Values: [_values]});
              }
            }); 
          }
        }
        
        const dashFrameOptions = {
            url: dashResponse.data,
            container: dashDiv,
            scrolling: 'yes',
            width: '100%',
            printEnabled: 'yes'
        };
       
        const contentOptions = {
          toolbarOptions: {
            export: true,
            undoRedo: true,
            reset: true
          }
        };

        if(parameterOption.length > 0) {
          console.log("Parameter Option: ", parameterOption);
          Object.defineProperty(contentOptions, "parameters", { value: parameterOption, enumerable: true });
          console.log("Content Options: ", contentOptions);
        }

        dashDiv.innerHTML = '';

        await dashEmbeddingContext.embedDashboard(dashFrameOptions, contentOptions);        
        
      } else {
        topicDiv.innerHTML =`<div>${topicResponse ? topicResponse.data : "No Topic Specified"}</div>` 
        dashDiv.innerHTML = `<div>${dashResponse.data}</div>`;
      }
    }

    if(topicResponse){

      console.log("Embed URL: ", topicResponse.data)

      if(topicResponse.status === 200){
        const topicFrameOptions = {
          url: topicResponse.data,
          container: topicDiv,
          height: '100%',
          width: '100%'
        };

        const qOptions = {
          panelOptions: {
              panelType: 'SEARCH_BAR',
              focusedHeight: '250px',
              expandedHeight: '500px'
          },
          showTopicName: true, 
          allowTopicSelection: true,
          searchPlaceHolderText: 'let\'s see if this works first.'
        };

        topicDiv.innerHTML = '';

        await topicEmbeddingContext.embedGenerativeQnA(topicFrameOptions, qOptions);

      }
    }
}

export type Props = {
  dash: IReportData
}

function Dashboard(props: Props) {

  const [dashLoaded, setDashLoaded] = useState(false);
  const dash = props.dash;
  console.log("Dash: ", dash);
  const [qTopicId] = useState(dash.QTopicId ? dash.QTopicId : '')
  const [dashStyleHeight] = useState(qTopicId.length > 0 ?(qTopicId === "0" ? "0vh" :"75vh") : "80vh")
  const [topicStyleDisplay] = useState(qTopicId.length > 0 ? "block" : "none")
  const [dashStyle] = useState<CSSProperties>({
    overflow: "auto", 
    height: dashStyleHeight, 
    minWidth: "100%",
    zIndex: -9999,
    position: "absolute"
  });
  const [topicStyle] = useState<CSSProperties>({
    overflow: "auto", 
    height: "80vh", 
    minWidth: "100%",
    display: topicStyleDisplay,
    zIndex: 1,
    position: "absolute"
  });
  
  useEffect(() => {
    
    const getDash = async () => {
      setDashLoaded(false);

      await embedDashboard(dash);

      setDashLoaded(true);
    } 

    getDash();
  
  }, [dash]);
  
  return (
    <div>
      {!dashLoaded &&

        <div className="tw-h-screen">
          <div className= "tw-h-4/6 tw-grid tw-w-full tw-place-content-center">
            <SpinnerContainer />
          </div>
        </div>

      }
      <div id="topicContainer" style={topicStyle}>
        </div>
      
      <div id="dashContainer" style={dashStyle}>
        </div>   
      
    </div>
  );

  
}

export default Dashboard;